<script setup lang="ts">
import { useAuthenticator } from '@aws-amplify/ui-vue';
import Userback, { type UserbackWidget } from '@userback/widget';
import { fetchUserAttributes } from 'aws-amplify/auth';
import axios from 'axios';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import { userStore } from '@/store/user';
import { client } from '@/services/client';
import { type Confirm, confirmKey } from '@/injection-keys';
import { useLoading } from '@/composables/loading';
import type { SidebarMenuItem } from '@/components/layout/layout-sidebar.vue';

const confirmWithDialog = useConfirm();
const USERBACK_TOKEN = import.meta.env.VITE_USERBACK_TOKEN;

const confirm: Confirm = ((confirmationOptions, severity = 'danger') => new Promise(
  resolve => {
    confirmWithDialog.require({
      rejectClass: 'p-button-secondary p-button-text',
      rejectLabel: 'Abbrechen',
      acceptClass: severity === 'danger' ? 'p-button-danger' : undefined,
      accept: () => resolve(true),
      reject: () => resolve(false),
      ...confirmationOptions,
    });
  },
));

provide(confirmKey, confirm);

const menuItems = computed<SidebarMenuItem[]>(() => {
  if (!userStore.loggedIn) {
    return [];
  }

  const accessControl = (allow: boolean, item: SidebarMenuItem) => (allow ? item : undefined);

  const items: Array<SidebarMenuItem | undefined> = [
    {
      label: 'Dashboard',
      icon: fasChartSimple,
      route: { name: 'Dashboard' },
    },
    accessControl(userStore.user.role.canSeeTargets, {
      label: 'Targets',
      icon: fasSheetPlastic,
      route: { name: 'TargetManagement' },
    }),
    accessControl(userStore.user.role.canSeeChannels, {
      label: 'Kanäle',
      icon: fasCompass,
      route: { name: 'ChannelManagement' },
    }),
    accessControl(userStore.user.role.canSeeUsers, {
      label: 'Benutzer & Teams',
      icon: fasUser,
      route: { name: 'UserManagement' },
    }),
    {
      label: 'Katalog',
      icon: fasRectangleVerticalHistory,
      route: { name: 'CatalogManagement' },
    },
    // ToDo: this needs to be refactored as soon as administration view contains more contents other than roles
    accessControl(userStore.user.role.canSeeUserRoles, {
      label: 'Administration',
      icon: fasGear,
      route: { name: 'Administration' },
    }),
    accessControl(userStore.user.role.canSeeBilling, {
      label: 'Abrechnung',
      icon: fasLandmark,
      route: { name: 'Billing' },
    }),
  ];

  return items.filter((item): item is SidebarMenuItem => !!item);
});

const sidebarVisible = ref(true);

const customerOverlayVisible = ref(false);
const showCustomerOverlay = ref(false);
if (import.meta.env.VITE_STAGE_NAME !== 'prod' || window.location.hostname.startsWith('demo')) {
  showCustomerOverlay.value = true;
}

let userBack: UserbackWidget | null = null;
const auth = useAuthenticator();

async function setupUserBack() {
  if (!USERBACK_TOKEN || auth.authStatus !== 'authenticated') return;
  userBack = await Userback(USERBACK_TOKEN);
  const userAttributes = await fetchUserAttributes();

  userBack.identify(userAttributes.sub ?? 'dummy', {
    name: `${userAttributes.given_name} ${userAttributes.family_name}`,
    email: userAttributes.email,
  });
  userBack.setCategories('cockpit');
}

function wakeUpCore() {
  return client.post('/wakeup', {});
}

const isLoggedIn = computed(() => userStore.loggedIn);

watchEffect(() => {
  if (isLoggedIn.value) {
    setupUserBack();
    wakeUpCore();
  } else {
    userBack?.destroy();
    userBack = null;
  }
});

const { loading } = useLoading();

const route = useRoute();
const toast = useToast();

axios.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    let summary = 'Es ist ein Fehler aufgetreten!';
    if (response?.data?.title) {
      summary = response?.data?.title;
    } else if (response?.data?.message) {
      summary = JSON.parse(response?.data?.message)?.title;
    }

    let detail = response?.data;
    if (error.message) {
      detail = error.message;
    } else if (response?.data?.message) {
      detail = JSON.parse(response?.data?.message)?.message;
    }

    toast.add({
      severity: response.status === 409 ? 'warn' : 'error',
      summary,
      detail,
    });

    return Promise.reject(error);
  },
);
</script>

<template>
  <layout-header
    v-if="isLoggedIn"
    :sidebar-visible="sidebarVisible"
    @toggle-sidebar="sidebarVisible = !sidebarVisible"
    @open-customer-overlay="customerOverlayVisible = !customerOverlayVisible"
  />

  <i-progress-bar
    v-if="loading"
    class="!fixed top-32 left-0 right-0 z-[101]"
  />

  <layout-content :is-logged-in :class="sidebarVisible ? 'sidebarVisible' : ''">
    <template #sidebar>
      <layout-sidebar
        v-if="isLoggedIn"
        :visible="sidebarVisible"
        :menu-items
      />
    </template>

    <template #content>
      <suspense>
        <router-view :key="route.fullPath" />

        <template #fallback>
          <div class="size-full flex justify-center items-center">
            <div class="w-[30rem]">
              <img src="/src/assets/intellivend_logo.svg">
              <i-progress-bar />
            </div>
          </div>
        </template>
      </suspense>
    </template>
  </layout-content>

  <p-dialog
    v-if="showCustomerOverlay"
    v-model:visible="customerOverlayVisible"
    modal
    header="Test-Kundendaten"
    :style="{ width: '90vw', 'max-width': '90vw' }"
  >
    <customer-table />
  </p-dialog>

  <p-confirm-dialog :draggable="false" />
  <p-toast position="bottom-right" />
</template>

<style>

.userback-button {
  @apply !bg-accent-800;
}
</style>
