import type { Options, SuccessResponse } from '@/models/common';
import type {
  BulkUpdateUserInput,
  CreateUserInput, UpdateUserInput,
  User,
} from '@/models/user';
import { client } from '@/services/client';

export const userService = {
  getUsers() {
    return client.get<User[]>('/users');
  },
  getCompanies() {
    return client.get<string[]>('/users/companies');
  },

  getUser(id: string) {
    return client.get<User>(`/users/${id}`);
  },

  createUser(input: CreateUserInput) {
    return client.post<User>('/users', input);
  },

  updateUser(id: string, input: UpdateUserInput) {
    return client.patch<User>(`/users/${id}`, input);
  },

  deleteUser(id: string): Promise<SuccessResponse> {
    return client.delete<SuccessResponse>(`/users/${id}`);
  },

  async getDropdownOptions(withCognitoId = false): Promise<Options<string>> {
    const users = await this.getUsers();
    return users.map(user => ({
      label: `${user.firstName} ${user.lastName}`,
      value: withCognitoId ? user.cognitoId : user.id,
    }));
  },

  bulkUpdateUsers(input: BulkUpdateUserInput): Promise<SuccessResponse> {
    return client.patch<SuccessResponse>('/users', input);
  },

};
