export function getTenantBackendUrl(): string {
  const baseUrl = 'cockpit-backend.intellivend.app';
  const { host } = window.location; // demo.cockpit.intellivend.app

  if (host.includes('localhost')) {
    return import.meta.env.VITE_BACKEND_ENDPOINT;
  }

  const tenantName = host.split('.cockpit.')[0];
  return `https://${tenantName}.${baseUrl}/api`;
}
