import { getCurrentUser } from 'aws-amplify/auth';
import type { NavigationGuardWithThis, RouteLocation, RouteLocationRaw } from 'vue-router';

import { userStore } from '@/store/user';

const accessDeniedRoute: RouteLocationRaw = { name: 'AccessDenied' };

function isAccessDenied(to: RouteLocation, allow: boolean, ...routes: string[]): boolean {
  return !allow && routes.some(route => route === to.name);
}

function isPublicRoute(to: RouteLocation): boolean {
  return ['Login', 'AccessDenied'].some(route => route === to.name);
}

async function isAuthenticated(): Promise<boolean> {
  try {
    await getCurrentUser();
    return userStore.loggedIn;
  } catch {
    return false;
  }
}

const authGuard: NavigationGuardWithThis<void> = async (to: RouteLocation) => {
  const authenticated = await isAuthenticated();
  if (!authenticated && !isPublicRoute(to)) {
    return {
      name: 'Login',
      query: {
        redirect: to.fullPath,
      },
    };
  }

  // check permissions for each view
  if (userStore.loggedIn) {
    const { role } = userStore.user;

    if (isAccessDenied(to, role.canSeeTargets, 'TargetManagement', 'TargetDetails')) {
      return accessDeniedRoute;
    }

    if (isAccessDenied(to, role.canSeeChannels, 'ChannelManagement', 'ChannelDetails')) {
      return accessDeniedRoute;
    }

    if (isAccessDenied(to, role.canSeeUsers, 'UserManagement', 'UserDetails')) {
      return accessDeniedRoute;
    }

    if (isAccessDenied(to, role.canSeeBilling, 'Billing')) {
      return accessDeniedRoute;
    }

    // ToDo: this needs to be refactored as soon as administration view contains more contents other than roles
    if (isAccessDenied(to, role.canSeeUserRoles, 'Administration')) {
      return accessDeniedRoute;
    }
  }

  return true;
};

export default authGuard;
